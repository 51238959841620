import "./about.css";

export function About() {
  return (
    <div className="about">
      <p>
        <b>About</b> page...
      </p>
    </div>
  );
}

export default About;
