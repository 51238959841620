import "./App.css";
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/index.js";
import Layout from "./components/Pages/layout.js";
import About from "./components/Pages/about.js";

import Notebook from "./m-notebook/index.js";
import Mnotebook from "./notebook/index.js";

function App() {
  const [isNotebookEnabled, setNotebookEnabled] = useState(true);

  useEffect(() => {
    const isSmallResolution = window.innerWidth <= 768;
    setNotebookEnabled(!isSmallResolution);
  }, []);

  return (
    <>
      <div className="navbar">
        <Navbar />
      </div>
      <div className="app">
        <Routes>
          <Route path="/" element={<Layout />} />
          {isNotebookEnabled && (
            <Route path="/notebook" element={<Mnotebook />} />
          )}
          <Route path="/notebook" element={<Notebook />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
