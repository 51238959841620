import "./index.css";

export function Notebook() {
  return (
    <>
      <body>
        <div className="notebook-header-spread">
          <div className="notebook-header">
            <h1>Introduction for PC</h1>
          </div>
        </div>
      </body>
      <div className="notebook-main">
        <center>
          <h2>Greetings wonderer!</h2>
          <div className="notebook-main-underline"></div>
          <br />
          <p>
            WHY?
            <u>
              <b>recreating the notebook</b>
            </u>
            .
          </p>
        </center>
      </div>    </>
  );
}

export default Notebook;
