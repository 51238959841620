import "../m-notebook/index.css";

export function Mnotebook() {
  return (
    <>
      <body>
        <div className="notebook-header">
          <h1>Introduction for mobile</h1>
        </div>
      </body>
      <div className="notebook-main">
        <center>
          <h2>Greetings wonderer!</h2>
          <br />
          <p>
            Thank you for checking out my website. This website is about my
            chemistry notebook. I am going to learn CSS on this website by{" "}
            <u>
              <b>recreating the notebook</b>
            </u>
            .
          </p>
        </center>
      </div>
    </>
  );
}

export default Mnotebook;
